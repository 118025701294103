import { observable, runInAction, action, makeObservable, override, computed } from 'mobx'

import { getCookies, setCookies, removeCookies } from 'libs/common/cookies'

import { ELO_PUBLISHER_OVERVIEW_PATH, ELO_PUBLISHER_SIGN_IN_PARAM } from 'constants/routes/eloPublisher.constants'
import { SALES_TEAM_MEMBER_OVERVIEW_PATH } from 'constants/routes/salesTeamMember.constants'
import { ROOT_URL } from 'constants/general.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'
import { formatDateTime } from '@elo-kit/utils/dateTime.utils'

import { getAffiliateCookies } from 'utils/affiliatePrograms.utils'
import { setParamsToCookies } from 'utils/cookiesConsents.utils'
import { APP_USERS_SIGN_IN_PATH } from 'constants/routes/app.constants'

import {
  SIGN_UP_NEW_PROFILE_TYPE_PARAM,
  SIGN_UP_NEW_PROFILE_EPID_PARAM,
  SIGN_UP_NEW_PROFILE_EMAIL_PARAM,
} from 'constants/login.constants'

import { TEAM_MEMBER_OVERVIEW_PATH } from 'constants/routes/teamMember.constants'
import { PUBLISHER_AFFILIATE_PROGRAMS_PATH } from 'constants/routes/publisher.constants'
import { isWindowEnv } from 'utils/env.utils'
import { getSearchParams } from 'utils/queryString.utils'
import { ShopRootStore } from 'shop/stores/shopRoot.store'

import { apiClient } from 'utils/requests.utils'
import { patchLink } from 'utils/link.utils'

import { UserResp, UpdateItemBody, UserApi, createUserApi } from '../api/user.api'
import SharedStore from './shared.store'
import { createAffiliateProgramsApi } from '../api/affiliatePrograms.api'
import { acceptItem as acceptAffiliateNode } from '../../publisher/api/affiliateNodes.api'

// there are cases when no response, so no nested error and data fields
const checkLocale = (resp: UserResp, shouldReload = true) => {
  const { data, error } = resp || {}
  const isShop = getCookies('cabinet_profile_type') === ACTIVE_PROFILES.shop

  if (!isShop && resp && Object.keys(resp).length !== 0 && !error && getCookies('locale') !== data?.locale) {
    setCookies('locale', data?.locale).then(() => {
      if (shouldReload && isWindowEnv()) window.location.reload()
    })
  }
}

const affiliateCookiesParams = [
  'parent_publisher',
  'invite_program',
  'affiliate_invitee_username',
  'affiliate_node_token',
]

export class UserStore extends SharedStore<any> {
  storeName = 'UserStore'
  declare childApi: UserApi
  affiliateProgramsApi

  constructor(root?: ShopRootStore) {
    super()
    makeObservable(this)

    this.childApi = createUserApi(root?.apiClient ?? apiClient)
    this.affiliateProgramsApi = createAffiliateProgramsApi(root?.apiClient ?? apiClient)
  }

  @observable customInteractionUrl = null
  @observable becomeSellerModalOpen = false

  @action isBecomeSellerSignIn = (): boolean => {
    const preferredProfileType = getCookies(SIGN_UP_NEW_PROFILE_TYPE_PARAM)
    const affiliateCookies = getAffiliateCookies()
    const epid = affiliateCookies?.epid
    const { profileTypes = [] } = this.item

    return (
      (Boolean(preferredProfileType) || epid) && !profileTypes.includes(preferredProfileType || ACTIVE_PROFILES.seller)
    )
  }

  @action clearBecomeSellerSettings = (): void => {
    removeCookies(SIGN_UP_NEW_PROFILE_TYPE_PARAM)
    removeCookies(SIGN_UP_NEW_PROFILE_EMAIL_PARAM)
    removeCookies(SIGN_UP_NEW_PROFILE_EPID_PARAM)
  }

  @action toggleBecomeSellerModal = (cleanUp = true, value = null): void => {
    this.becomeSellerModalOpen = value === null ? !this.becomeSellerModalOpen : value
    if (!this.becomeSellerModalOpen) {
      removeCookies('shared_theme_token')
      removeCookies('shared_theme_type')

      /* Clean Up become seller sign up for existing user flow */
      cleanUp && this.clearBecomeSellerSettings()
    }
  }

  @action
  updateEmail(data: { email: string; currentPassword: string; recaptcha: any }) {
    return this.childApi.updateEmail(data)
  }

  @override
  async fetchItem(shouldReload?: boolean) {
    runInAction(() => (this.loading = true))
    const resp = await this.childApi.fetchItem()
    this.setItem(resp)
    this.checkRefererLink()
    await this.managePublisher()
    if (this.customInteractionUrl) {
      window.location.href = patchLink(this.customInteractionUrl)
    } else {
      checkLocale(resp, shouldReload)
      runInAction(() => (this.loading = false))

      if (this.isBecomeSellerSignIn()) {
        this.toggleBecomeSellerModal(false)
      }
    }
    return resp
  }

  @override
  async updateItem(data: UpdateItemBody) {
    runInAction(() => (this.loading = true))
    const resp = await this.childApi.updateItem(data || (this.item as UpdateItemBody))
    checkLocale(resp)
    this.setItem(resp)
    runInAction(() => (this.loading = false))
    return resp
  }

  checkRefererLink() {
    const sessionStorage = isWindowEnv() && navigator.cookieEnabled && window.sessionStorage
    const refererLink = sessionStorage ? sessionStorage.getItem('referer_link') : ''
    if (refererLink && this.item.id) {
      this.customInteractionUrl = refererLink
      sessionStorage.removeItem('referer_link')
    }
  }

  async managePublisher() {
    const {
      invite_program: affiliateProgramToken,
      affiliate_node_token: affiliateNodeToken,
      parent_publisher: parentPublisher,
    } = this.getAffiliateParams()
    if (this.item.id) {
      if (affiliateProgramToken) {
        await this.manageAffiliateLanding(affiliateProgramToken, { parentPublisher })
      }
      if (affiliateNodeToken) {
        await this.manageAffiliateNodeInvite(affiliateNodeToken)
      }
    }
  }

  processResponse = () => {
    this.clearAffiliateCookies()
    this.customInteractionUrl = patchLink(`${ROOT_URL}${PUBLISHER_AFFILIATE_PROGRAMS_PATH}`)
  }

  async manageAffiliateLanding(affiliateProgramToken: string, data: { parentPublisher: string }) {
    await this.checkPublisher()
    await this.affiliateProgramsApi.accept(affiliateProgramToken, data)
    this.processResponse()
  }

  async manageAffiliateNodeInvite(affiliateNodeToken: string) {
    await this.checkPublisher()
    await acceptAffiliateNode(affiliateNodeToken)
    this.processResponse()
  }

  async checkPublisher() {
    const publisherPresent = this.item.profileTypes.indexOf(ACTIVE_PROFILES.publisher) === -1
    if (publisherPresent) await this.childApi.createProfile({ profileType: ACTIVE_PROFILES.publisher })
  }

  getAffiliateParams = (): {
    invite_program?: string
    affiliate_node_token?: string
    parent_publisher?: string
    affiliate_invitee_username?: string
  } => {
    const query = getSearchParams()

    return affiliateCookiesParams.reduce(
      (result, key) => ({
        ...result,
        [key]: query[key] || getCookies(key),
      }),
      {}
    )
  }

  setAffiliateCookies = () => {
    const params = getSearchParams()
    if (params.invite_program || params.affiliate_node_token) {
      setParamsToCookies(params)
    }
  }

  clearAffiliateCookies = () => {
    affiliateCookiesParams.forEach((paramName) => setCookies(paramName, ''))
  }

  isSeller() {
    return this.item.profileTypes.indexOf('seller') >= 0
  }

  getCabinetLink(urlParams = '') {
    const { profileTypes = [] } = this.item

    if (urlParams === ELO_PUBLISHER_SIGN_IN_PARAM) {
      return ELO_PUBLISHER_OVERVIEW_PATH
    }

    if (profileTypes.indexOf('manager') >= 0) {
      setCookies('cabinet_profile_type', 'manager')
      return '/admin'
    }

    if (profileTypes.indexOf('seller') >= 0) {
      setCookies('cabinet_profile_type', 'seller')
      return `/cabinet${urlParams}`
    }

    if (profileTypes.indexOf('payer') >= 0) {
      setCookies('cabinet_profile_type', 'payer')
      return `/payer${urlParams}`
    }

    if (profileTypes.indexOf('publisher') >= 0) {
      setCookies('cabinet_profile_type', 'publisher')
      return `/publish${urlParams}`
    }

    if (profileTypes.indexOf('team_member') >= 0) {
      setCookies('cabinet_profile_type', 'team_member')
      return `${TEAM_MEMBER_OVERVIEW_PATH}${urlParams}`
    }

    if (profileTypes.indexOf('sales_team_member') >= 0) {
      setCookies('cabinet_profile_type', 'sales_team_member')
      return `${SALES_TEAM_MEMBER_OVERVIEW_PATH}${urlParams}`
    }

    if (profileTypes.indexOf('elo_publisher') >= 0) {
      setCookies('cabinet_profile_type', 'elo_publisher')
      return `${ELO_PUBLISHER_OVERVIEW_PATH}${urlParams}`
    }

    return `${APP_USERS_SIGN_IN_PATH}${urlParams}`
  }

  formatDateTimeCell = (dateTime) => formatDateTime(dateTime, DATE_FORMATS.DDMMYYYYHHmm, this.item.timeZoneName)

  @computed get userId() {
    return this.item.id
  }
}

export default new UserStore()
